a.ninjaLink {
    color: inherit;
    text-decoration: inherit;
}

.unselectable {
    user-select: none;

    * {
        user-select: none;
    }
}

.grow {
    transition: all 0.085s ease-in-out;
}
.grow:hover {
    transform: scale(1.07);
}
.grow:active {
    &:hover {
        transform: scale(1);
        transition: all 0s linear;
    }
}

.growSlight {
    transition: all 0.06s ease-in-out;
}
.growSlight:hover {
    transform: scale(1.025);
}
.growSlight:active {
    &:hover {
        transform: scale(1);
        transition: all 0s linear;
    }
}
