#loadingRoom {
    width: inherit;
    height: calc(100vh - 10px);

    display: flex;
    align-items: center;
    justify-content: center;

    ._centered {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
