$textColor: #72523e;
$globalBackground: rgb(252, 251, 251);

// $gradientBackground: linear-gradient(187deg, rgba(61, 55, 55, 0.85), rgba(80, 53, 53, 0.21) 70.71%),
//     linear-gradient(127deg, rgba(56, 61, 60, 0.85), rgba(50, 61, 50, 0.21) 70.71%),
//     linear-gradient(346deg, rgba(41, 41, 51, 0.85), rgba(37, 37, 82, 0.21) 70.71%);
$gradientBackground: #f2efe7;
// ———————————————————————————
// General Styles: Buttons
// ———————————————————————————

$standardButtonBorder: rgba(white, 0.275);

$primaryButtonBack: 'TODO';
// $primaryButtonColor: white;
$primaryButtonColor: black;
$primaryButtonBackHover: 'TODO';

$darkBack: rgb(66, 63, 63);
// $secondaryButtonBack: linear-gradient(135deg, #cedaff, #efa2ff);
$secondaryButtonBack: rgb(233, 213, 170);
// $secondaryButtonColor: white;
$secondaryButtonColor: black;
// $secondaryButtonBackHover: white; //
$secondaryButtonBackHover: linear-gradient(137deg, #652efc, #9c37d6);
$secondaryButtonColorHover: white;

$tertiaryButtonBack: rgba(0, 0, 0, 0); // Transparent
// $tertiaryButtonColor: white;
$tertiaryButtonColor: black;
$tertiaryButtonBackHover: $secondaryButtonBackHover;

$disabledButtonBack: lighten($darkBack, 4);
$disabledButtonColor: rgba(white, 0.45);

// ═══════════════════════════════════════

// ———————————————————————————
// Modal
// ———————————————————————————

$bigInputPaneEmptyBack: #4b4848;
// $bigInputPaneBack: rgb(243, 187, 104);
$bigInputPaneBack: linear-gradient(137deg, rgb(255, 227, 67), rgb(243, 187, 104));
$bigInputPaneColor: #333;

// ═══════════════════════════════════════

// ———————————————————————————
// Room: General
// ———————————————————————————
