@import '../styles/colors';
@import '../styles/fonts';

// —————————————————
// Layout
// —————————————————
#root {
    background: rgba(0, 0, 0, 0);
    position: relative;
}

button {
    cursor: pointer;
}

// —————————————————
// Inner
// —————————————————

$topBarHeight: 50px;
$actionBarHeight: 60px;

#roomContent {
    // height: calc(100vh - 50px - 60px);
    height: calc(100vh - 60px);
    padding: 10px 30px;
}

#videoGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px); // 300-pixel wide videos
    grid-auto-rows: 250px; // Every row is 300 pixels tall
}

video {
    width: 100%;
    height: 100%;
    // Zoom in every video so that its aspect ratio is always that of the outer
    // container (a 300px by 300px square)
    object-fit: cover;
}

.puckWrapper {
    // Default max-width of a puck.
    max-width: 200px;

    .puck {
        // Default puck dimensions.
        width: 200px;
        height: 200px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .puck {
        z-index: 80;
        & video {
            z-index: 80;
        }
    }
    .muteIndicator {
        z-index: 79;
    }
    #myPuck {
        z-index: 50;
        & video {
            z-index: 50;
        }
    }
    #myPuckWrapper .muteIndicator {
        z-index: 49;
    }

    ul._puckContext {
        margin-block-start: 7.5px;
        padding: 0;
        color: black;
        list-style: none;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li {
            cursor: default;
            display: inline-flex;
            font-size: 13.25px;
            font-weight: 500;
            padding: 2px 11.5px;
            margin-right: 3px;
            border-radius: 4.25px;
            background: rgba(255, 255, 255, 0.497);

            user-select: none; // Prevents users from highlighting text.
            user-drag: none; // Prevents users from dragging text; prevents interference with mousedrag.
        }

        li._muted,
        li._noCam,
        li._volumePercent {
            background: rgb(106, 14, 226);
            color: white;
        }
    }
}

.puck {
    height: 200px;
    border-radius: 10000px;
    overflow: hidden;
    border: 5.252px solid rgb(175, 220, 40);

    position: relative;

    .pointer {
        position: absolute;
        // top: 60%;
        // left: 70%;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        //transform: translate(-50%, 200%);
        height: 15px;
        width: 15px;
        border-radius: 10000px;
        //background-color: blue;
        border: 20px solid rgba(255, 255, 255, 0.8);
        transform-origin: -77.5px 0;
    }
}

#myPuck {
    border: 5.25px solid cyan;
}

.muteIndicator {
    z-index: 100;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    right: 20px;
    transform: translateY(13px);
    width: 30px;
    height: 30px;
    background-color: rgb(192, 0, 0);
    align-items: center;
    border-radius: 30px;
    margin: auto;
    .MuiSvgIcon-root {
        margin: 7.5px;
        fill: white;
    }
}
// #myPuck {
//     border: 7px solid cyan;
// }

// —————————————————
// Ping area
// —————————————————

#pingArea {
    padding: 5.5px 13px;
    background: rgba(0, 0, 0, 0.33);
    font-size: 12.5px;
    border-bottom-left-radius: 5px;
    position: absolute;
    top: 0;
    right: 0;

    ._ping {
        display: inline-flex;
        min-width: 10px;
    }
}

// —————————————————
// Top bar
// —————————————————

#topBar {
    background: transparent;
    display: flex;
    align-items: center;
    height: $topBarHeight;
    margin-right: 10px;
    .MuiSvgIcon-root {
        fill: #72523e;
    }
    .documentShareIcon,
    .whiteboardIcon,
    .shareScreenIcon {
        width: 21px;
        height: 21px;
    }
    .primaryButton.iconButton,
    .secondaryButton.iconButton,
    .tertiaryButton.iconButton {
        width: 44px;
        height: 44px;
        box-sizing: border-box;
        border-width: 1.88px;
    }
    & > ._barRight {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        // width: 200px;
        height: 30px;
    }
    #actionBarInfo {
        display: flex;
        align-items: center;
        font-size: 22px;
        margin-left: 10px;
    }
}

// —————————————————
// Action bar
// —————————————————

#actionBar {
    background: transparent;
    //background: rgba(0, 0, 0, 0.33);
    height: $actionBarHeight;
    border-radius: 7px;
    display: flex;
    align-items: center;
    // padding-left: 20px;
    // padding-bottom: 20px;
    & > * {
        margin-right: 10px;
    }

    .MuiSvgIcon-root {
        fill: #72523e;
    }

    .primaryButton.iconButton,
    .secondaryButton.iconButton,
    .tertiaryButton.iconButton {
        width: 44px;
        height: 44px;
        box-sizing: border-box;
        border-width: 1.88px;

        &:hover:not(:active) {
            background: white;
        }
    }

    ._barRight {
        margin-left: auto;
        height: inherit;
        display: flex;
        align-items: center;
    }

    /** This bar will stick in the bottom left. */
    $userBarInteractionBack: rgb(233, 213, 170);
    ._userBar {
        z-index: 1200;
        background: rgba(#efe4cb, 0.88);
        height: inherit;
        display: flex;
        align-items: center;
        padding: 0 12.5px 0 5px;
        border-top-right-radius: 20px;

        .primaryButton.iconButton,
        .secondaryButton.iconButton,
        .tertiaryButton.iconButton {
            &:hover:not(:active) {
                background: $userBarInteractionBack;
            }
        }
    }

    ._userCard {
        cursor: pointer;

        // height: inherit;
        display: flex;
        align-items: center;
        padding: 7.5px 7.5px;
        padding-right: 10px;
        margin-right: 2.5px;
        border-radius: 3.5px;
        ._icon {
            display: flex;
            align-items: center;
            float: left;
            font-size: 24px;
            margin-right: 5.25px;
        }
        ._nickname {
            display: flex;
            align-items: center;
            font-family: $headingStack;
            font-weight: 500;
            line-height: 1;
            font-size: 13px;
        }
        &:hover:not(:active) {
            background: $userBarInteractionBack;
        }
    }

    #selfNoCamButton {
        // width: 72px;
        display: flex;
        align-items: center;
    }
}
