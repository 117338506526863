@import '../styles/colors';
@import '../styles/fonts';

$actionButtonBack: #b627da;

// ——————————————————————————————
// Modal

.modalWrapper {
    outline: none;

    margin: auto;
    background: white;
    // Configure size.
    border-radius: 6.25px;
    width: 80vw;
    max-width: 740px;
    max-height: 80vh;

    // Position in center.
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -30%);

    box-shadow: 0px 1px 30px rgba(black, 0.15), 0px 1px 6px rgba(black, 0.1);
}

.myModal {
    width: 100%;
    height: 100%;

    margin: 0;
    background: $gradientBackground;
    border-radius: 6.25px;
    padding: 27.5px 35px;
    padding-bottom: 40px;
    position: relative;

    border-top: 1px solid rgba(white, 0.15);
    border-bottom: 1px solid rgba(black, 0.3);

    /** Close (X) button. AKA "Back button". */
    ._close {
        &:before {
            content: '✕';
        }
        position: absolute;
        top: 0;
        right: 0;
        padding: 12.5px 18.75px;
        background: none;
        border: none;
        border-top-right-radius: 6.25px;
        color: white;
        &:hover:not(:active) {
            background: rgba(white, 0.1);
            color: rgba(white, 0.8);
        }
    }

    h1 {
        margin: 0;
        padding-bottom: 22.5px;
        font-size: 26px;
    }

    ._actionButton {
        font-size: 26.5px;
        letter-spacing: -0.022em;
        margin-top: 7.5px;
        text-decoration: none;
        color: white;
        padding: 12.5px 32.5px;
        display: inline-block;
        position: relative;
        border: 1px solid rgba(0, 0, 0, 0.21);
        border-bottom: 4px solid rgba(0, 0, 0, 0.21);
        border-radius: 4px;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
        background: $actionButtonBack;
    }
}

// —————————————————————————————————

@media (max-width: 768px) {
    .modalWrapper {
        width: 90vw;
    }
    .myModal {
        padding-left: 27.5px;
        padding-right: 27.5px;
    }
}

// ————————————————————————————————
// Text input

.bigInputPane {
    margin: auto;
    justify-content: left;
    border-radius: 10px;
    margin-top: 22.5px;
    margin-bottom: 25px;
    max-width: 475px;
    // height: 150px;
    padding: 13.75px 17.5px;
    // width: 40vw;
    background: $bigInputPaneEmptyBack;
    color: white;
    box-shadow: 0px 0px 5px 5px rgba(white, 0.06);

    font-size: 24.75px;
    letter-spacing: -0.15em;
    line-height: 1.2;

    & > input[type='text'] {
        font-family: $headingStack;
        outline: none;
        // When it has nothing in it:
        background: transparent;
        color: white;
        border: 0;
    }

    &.filled {
        background: $bigInputPaneBack;
        & > input[type='text'] {
            color: $bigInputPaneColor;
        }
    }
}
