@import 'colors';
@import 'fontImports';
@import 'fonts';

body {
    margin: 0;
    font-family: $fontStack;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 14.5px;
    line-height: 1.5;
    letter-spacing: -0.012em;

    color: $textColor;
    // background: $globalBackground;
    //color: white;
    background: $gradientBackground;

    min-height: calc(100vh - 10px);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// ———————————————————————————
// Sensical changes
// ———————————————————————————

button {
    cursor: pointer;
    font-family: $headingStack;
}

input[type='text'] {
    font-family: $fontStack;
}

h1,
h2,
h3 {
    font-family: $headingStack;
    font-weight: 500;
}

h1 {
    font-size: 30px;
    letter-spacing: -0.032em;
}
h2 {
    font-size: 24.5px;
    letter-spacing: -0.018em;
}

// ———————————————————————————
// Container changes
// ———————————————————————————

.container {
    max-width: calc(100% - 20px);
}

@media (min-width: 576px) {
    .container {
        max-width: calc(100% - 30px);
    }
}

@media (min-width: 767px) {
    .container {
        max-width: calc(100% - 40px);
    }
}

@media (min-width: 992px) {
    .container {
        max-width: calc(100% - 60px);
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: calc(100% - 80px);
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1200px;
    }
}

/* Small container */

.smallContainer {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 767px) {
    .smallContainer {
        max-width: calc(100% - 40px);
    }
}

@media (min-width: 992px) {
    .smallContainer {
        max-width: calc(100% - 70px);
    }
}

@media (min-width: 1110px) {
    .smallContainer {
        max-width: 1040px;
    }
}

// ———————————————————————————————————————————————————
// GLOBAL STYLES
// ———————————————————————————————————————————————————

// ——————————————————————————————
// Buttons
// ——————————————————————————————

.primaryButton,
.secondaryButton,
.tertiaryButton {
    // border: 2px solid $standardButtonBorder;
    border: 2px solid transparent;
    // border: 0;
    border-radius: 10000px;
    // padding: 4.375px 17.5px;
    padding: 8.25px 19.125px;
    padding-top: 9.75px;
    font-size: 16.25px;
    font-weight: 600;
    line-height: 1.05;

    transition: border-color 0.08s, background 0.08s, color 0.08s;
}

.primaryButton.smaller,
.secondaryButton.smaller,
.tertiaryButton.smaller {
    font-weight: 400;
    font-size: 14px;
    padding: 7.275px 19.125px;
    padding-top: 8.275px;
}

.primaryButton.iconButton,
.secondaryButton.iconButton,
.tertiaryButton.iconButton {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    box-sizing: content-box;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    padding: 0;
}

.primaryButton {
    color: $primaryButtonColor;
    // TODO: Fill this out.
}

.secondaryButton {
    background: $secondaryButtonBack;
    color: $secondaryButtonColor;

    &:hover:not(:active) {
        background: $secondaryButtonBackHover;
        color: $secondaryButtonColorHover;
    }
}

.tertiaryButton {
    background: $tertiaryButtonBack;
    color: $tertiaryButtonColor;

    &:hover:not(:active) {
        background: $tertiaryButtonBackHover;
        color: $tertiaryButtonColor;
    }
}

.primaryButton.disabled,
.secondaryButton.disabled,
.tertiaryButton.disabled {
    background: $disabledButtonBack;
    color: $disabledButtonColor;

    &:hover:not(:active) {
        background: $disabledButtonBack;
        color: $disabledButtonColor;
    }
}
